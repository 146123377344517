import React, { Component, Fragment } from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import Navbar from './Navbar';
import {isLoggedIn} from './Auth';
import {Redirect} from 'react-router';
import axios from 'axios';



class Checkout extends Component { 
componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);
  }
 render() {
   const AuthPath = () => {
      if(isLoggedIn()) {
        return null;
      } else {
        return <Redirect to="/login" />;
      } 
    }
 
 return (
 <Fragment> 
      <AuthPath />
      <Navbar />
      <StripeProvider apiKey="pk_live_fLupuOejqjpaZq8vNF9Tc93w">
      <div className="container">
        <h1 className="display-4 mt-5 mb-4 ml-2">PURCHASE A PACKAGE</h1>
	<Elements>
	  <CheckoutForm />
	</Elements>
        <h1 className="display-4 mt-5 mb-2 ml-2">SETUP RECURRING PAYMENTS</h1>
        <p className="mt-3 mb-4 ml-2">For full time membership only</p>

<stripe-buy-button
  buy-button-id="buy_btn_1O81cYJvEWC8sVxzBcXbm84N"
  publishable-key="pk_live_fLupuOejqjpaZq8vNF9Tc93w"
>
</stripe-buy-button>
      </div>
      </StripeProvider>
 </Fragment>
 );}
}

export default Checkout;
